import React, { useState } from "react";
import moment from 'moment';

const ArchiveManager = ({user, customer_service = {}}) => {
  const [savedManager, setSavedManager] = useState({
    _id: customer_service[user]?.customer_service_settings?.account_type,
  });

  const managers = [
    { name: "" },
    { _id: "fbzipper", name: "fbzipper" },
    { _id: "single_plus_2_profiles", name: "פרופיל אישי + 2 פרופילים" },
    { _id: "single_profile", name: "פרופיל אישי" },
  ];

  // 3.5 month = fbzipper
  // 500 < amount -> single
  // thursday single profile
  // + 2
const threeAndHalfMonthsAgo = moment().subtract(3.5, 'months');
const fivedaysago = moment().subtract(5, 'days').startOf('day');
const aMonthAgo = moment().subtract(28, 'days').startOf('day');

  console.log(555)
  if (!customer_service[user]?.customer_service_settings?.account_type) {

    if (moment(customer_service[user]?.created_at).isBefore(threeAndHalfMonthsAgo) && customer_service[user]?.monthly_payment_amount > 1000) {    
        fetch(
          `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/archive-manager/set`,

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: user,
              type: 'fbzipper',
            }),
          }
        );
    }
    if (customer_service[user]?.monthly_payment_amount === 351) {    
        fetch(
          `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/archive-manager/set`,

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: user,
              type: 'single_profile',
            }),
          }
        );
    }
    if (customer_service[user]?.monthly_payment_amount === 1755) {    
        fetch(
          `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/archive-manager/set`,

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: user,
              type: 'fbzipper',
            }),
          }
        );
    }
    if (moment(customer_service[user]?.created_at).isAfter(fivedaysago)) {    
        fetch(
          `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/archive-manager/set`,

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: user,
              type: 'single_profile',
            }),
          }
        );
    }
    if (moment(customer_service[user]?.created_at).isBefore(fivedaysago) && moment(customer_service[user]?.created_at).isAfter(aMonthAgo) && customer_service[user]?.monthly_payment_amount > 0 && customer_service[user]?.monthly_payment_amount < 1000) {    
        fetch(
          `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/archive-manager/set`,

          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: user,
              type: 'single_plus_2_profiles',
            }),
          }
        );
    }
  }



  const handleSelectChange = async (e) => {
    const selectedManagerId = e.target.value;
    const manager = managers.find((m) => m._id === selectedManagerId);
    setSavedManager(manager || {});
      const response = await fetch(
        `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/archive-manager/set`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: user,
            type: manager?._id || null,
          }),
        }
      );

      const data = await response.json();


    
    // alert(`Manager saved: ${manager?.name}`);
  };

  return (
    <>
      {!savedManager._id && <>sssss 
        {moment(customer_service[user]?.created_at).diff(moment(), 'days')}
        {<span style={{color: 'red'}}>{customer_service[user]?.monthly_payment_amount}</span>}
      </>}
      <select
        className="border rounded w-full p-2 mb-4"
        onChange={handleSelectChange}
        value={savedManager._id}
      >
        <option value="" disabled selected>
          סוג לקוח
        </option>
        {managers.map((manager) => (
          <option key={manager._id} value={manager._id}>
            {manager.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default ArchiveManager;