import React, { useState, useEffect } from "react";
import moment from "moment";
import { AiOutlineMessage } from "react-icons/ai"; // Importing a message icon

const ChatInterface = ({ className = "", type, user, latest_chats={} }) => {
  const [messages, setMessages] = useState([
    // { id: 1, sender: "bot", text: "Hi! How can I help you today?", time: new Date() },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(
        `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/chats`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type,
            user
          }),
        }
      );
      const data = await response.json();
      setIsLoading(false)
      setMessages(data.messages);
    } catch (err) {
      console.error('Error fetching master users:', err);
    }
  };


  const _setIsChatOpen = () => {
    setIsChatOpen(!isChatOpen)
    if (!isChatOpen) {
      fetchData();
    }
  };





  const handleSendMessage = async () => {
    if (newMessage.trim()) {


      const response = await fetch(
        `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/chats/new`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: newMessage,
            type,
            user,
          }),
        }
      );

      const data = await response.json();
      setMessages(data.messages);

      // setMessages([
      //   ...messages,
      //   { id: messages.length + 1, sender: "user", text: newMessage },
      // ]);
      setNewMessage("");

      // setTimeout(() => {
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { id: prevMessages.length + 1, sender: "bot", text: "Thanks for your message!" },
      //   ]);
      // }, 1000);
    }
  };

  const styles = {
    closeButton: {
      cursor: 'pointer',
      border: 'none',
      background: 'transparent',
      color: 'gray',
      fontSize: '20px',
      position: 'absolute',
      top: '10px',
      right: '10px',
    },

    
    chatIcon: {
      // padding: "12px",
      // backgroundColor: "#3b82f6",
      color: "white",
      borderRadius: "50%",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
      border: "none",
      background: 'TRANSPARENT',
    },
    chatIconHover: {
      // backgroundColor: "#2563eb",
    },
    chatWindow: {
      position: "absolute",
      left: 0,
      width: "320px",
      height: "384px",
      backgroundColor: "white",
      border: "1px solid #e5e7eb",
      borderRadius: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      marginTop: "16px",
      zIndex: 99999999,
    },
    messagesContainer: {
      flexGrow: 1,
      margin: "16px",
      overflow: "hidden",
      overflowY: "auto",
      padding: "16px",

      marginTop: '0px',
      paddingTop: '0px',

    },
    message: {
      display: "flex",
      marginBottom: "8px",
    },
    userMessage: {
      justifyContent: "flex-end",
    },
    botMessage: {
      justifyContent: "flex-start",
    },
    messageBubble: {
      borderRadius: "16px",
      padding: "12px",
      maxWidth: "240px",
      color: "white",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

    lineHeight: '23px',
    textAlign: 'left',


    },
    userBubble: {
      backgroundColor: "#3b82f6",
    },
    botBubble: {
      backgroundColor: "#374151",
    },
    inputContainer: {
      display: "flex",
      alignItems: "center",
      padding: "16px",
      borderTop: "1px solid #e5e7eb",
    },
    input: {
      flexGrow: 1,
      marginRight: "8px",
      padding: "8px",
      border: "1px solid #e5e7eb",
      borderRadius: "4px",
    },
    sendButton: {
      padding: "8px 16px",
      backgroundColor: "#3b82f6",
      color: "white",
      borderRadius: "4px",
      cursor: "pointer",
      border: "none",
    },
    sendButtonHover: {
      backgroundColor: "#2563eb",
    },
    profilePicture: {
      borderRadius: "50%",
      height: '64px',
      width: '64px',
    },
    messageTime: {
      lineHeight: "17px",
    },
    messagesHeader: {
      lineHeight: "21px",
    },
  };

  return (
    <div className={`relative ${className}`}>
      
      {/* Chat Icon */}
      <button
        style={styles.chatIcon}
        onClick={() => _setIsChatOpen(!isChatOpen)}
        onMouseOver={(e) => (e.target.style.backgroundColor = styles.chatIconHover.backgroundColor)}
        onMouseOut={(e) => (e.target.style.backgroundColor = styles.chatIcon.backgroundColor)}
      >
        {latest_chats[user + '_' + type] ? <AiOutlineMessage size={24} style={{color: 'red'}} /> : <AiOutlineMessage size={24} />}
              </button>

      {/* Chat Window */}
      {isChatOpen && (
        <div style={styles.chatWindow}>


          <div style={styles.messagesHeader}>{type} <button onClick={() => _setIsChatOpen(!isChatOpen)} style={styles.closeButton}>X</button></div>    
          <div style={styles.messagesContainer}>
            {messages.map((message) => (
              <div
                key={message.id}
                style={{
                  ...styles.message,
                  ...(message.sender === "user" ? styles.userMessage : styles.botMessage),
                }}
              >
                <div>
                  <div
                    style={{
                      ...styles.messageBubble,
                      ...(message.sender === "user" ? styles.userBubble : styles.botBubble),
                    }}
                  >
                    {message.text}
                  </div>
                  <div style={styles.messageTime}>{moment(message.time).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>

                <img
                  src={`https://ui-avatars.com/api/?name=${message.sender.first_name}+${message.sender.last_name}`}
                  alt="profile"
                  style={styles.profilePicture}
                  data-tip={`${message.sender.first_name} ${message.sender.last_name}`}
                />


              </div>
            ))}
          </div>

          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              style={styles.sendButton}
              onClick={handleSendMessage}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ChatSystem = ({type, user, latest_chats={}}) => {
  return (
    <div data-tip={latest_chats[user + '_' + type]?.text} style={{ display: "flex", flexWrap: "wrap", gap: "16px", display: 'inline-block' }}>
      <ChatInterface type={type} user={user} latest_chats={latest_chats}/> 
    </div>
  );
};

export default ChatSystem;
