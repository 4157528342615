import React, { useState } from "react";

const ManagerPicker = ({user, customer_service = {}}) => {
  const [savedManager, setSavedManager] = useState({
    _id: customer_service[user]?.customer_service_settings?.customer_service_rep,
  });

  const managers = [
    { name: "" },
    { _id: "659ec38d3b9a70d615d354a9", name: "אלדר" },
    { _id: "668bdce6f3b52828a123d398", name: "אלעד" },
    { _id: "679274628031a047ffef9da2", name: "תמיר" },
    { _id: "6792774d986b2948650a70ce", name: "עומרי" }
  ];

  const handleSelectChange = async (e) => {
    const selectedManagerId = e.target.value;
    const manager = managers.find((m) => m._id === selectedManagerId);
    setSavedManager(manager || {});
      const response = await fetch(
        `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/customer-service/set`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: user,
            rep_id: manager?._id || null,
          }),
        }
      );

      const data = await response.json();


    
    // alert(`Manager saved: ${manager?.name}`);
  };

  return (
    <>
      <select
        className="border rounded w-full p-2 mb-4"
        onChange={handleSelectChange}
        value={savedManager._id}
      >
        <option value="" disabled selected>
          משויך לאיש שירות להיום
        </option>
        {managers.map((manager) => (
          <option key={manager._id} value={manager._id}>
            {manager.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default ManagerPicker;