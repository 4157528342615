import React, { useState } from "react";
import Select from 'react-select';

const ManagerPicker = ({user, customer_service = {}}) => {


  const options = [
    { value: "new_customer", label: "לקוח חדש" },
    { value: "on_hold", label: "בהקפאה" },
    { value: "not_interested_in_continuing_next_month", label: "לא מעוניין להמשיך לחודש נוסף" },
    { value: "technical_issues", label: "בעיות טכניות" },
    { value: "requesting_refund", label: "מבקש זיכוי" },
    { value: "received_refund", label: "קיבל זיכוי" },
    { value: "return_to_customer_at_specific_time", label: "לחזור ללקוח בזמן מסוים" },
    { value: "send_message_to_return_to_customer", label: "שלח הודעה לחזור ללקוח" },
    { value: "call_us_to_return_to_customer", label: "חייג אלינו לחזור ללקוח" },
  ];

  const [savedManagers, setSavedManagers] = useState(options.filter((a) => {
    return customer_service[user]?.customer_service_settings?.customer_service_status.indexOf(a.value) > -1
  }));


  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 36,
      width: 800,
      fontSize: '16px',
      borderColor: state.isFocused ? '#007bff' : base.borderColor,
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : base.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#007bff' : '#ced4da'
      }
    }),
    option: (base, state) => ({
      ...base,
      color: 'black',
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e9ecef' : null,
      ':active': {
        backgroundColor: '#007bff'
      }
    }),
    menu: base => ({
      ...base,
      // You can control the menu placement with "menuPlacement" prop
      zIndex: 100
    }),
    // Add more custom styles if needed
  };

  // const handleSelectChange = selectedOptions => {
  //   setSavedManagers(selectedOptions || []);
  //   // Add your API call or other logic here as needed
  // };




  const handleSelectChange = async (selectedOptions) => {
    setSavedManagers(selectedOptions || []);
    // const selectedManagerId = e.target.value;
    // const manager = options.find((m) => m.value === selectedManagerId);
    // setSavedManagers(manager || {});
      const response = await fetch(
        `${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/customer-service/set-status`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: user,
            value: selectedOptions.map((a) => a.value),
          }),
        }
      );

      const data = await response.json();


    
    // alert(`Manager saved: ${manager?.label}`);
  };

  return (
    <>
      <div style={{lineHeight: '22px'}}>
        <Select
          value={savedManagers}
          onChange={handleSelectChange}
          options={options}
          isMulti
          style={{width: '500px'}}
          styles={customStyles}

          isClearable
          isSearchable
          placeholder="סטטוס"
          noOptionsMessage={() => "No managers found"}
        />

      </div>

      {/*<select
        className="border rounded w-full p-2 mb-4"
        onChange={handleSelectChange}
        value={savedManager.value}
      >
        <option value="" disabled selected>
          סטטוס
        </option>
        {options.map((manager) => (
          <option key={manager.value} value={manager.value}>
            {manager.label}
          </option>
        ))}
      </select>*/}
    </>
  );
};

export default ManagerPicker;