import React from "react";
import { useDrag } from "react-dnd";
import moment from 'moment';
import queryString from 'query-string';
import clock from '../../assets/icons/clock.svg';
import { FaExclamationTriangle, FaLock, FaBell, FaLink, FaComment, FaKey, FaLifeRing } from "react-icons/fa";
import LoadingIcons from 'react-loading-icons'

var fromNow = (date) => {
  // return moment(date).relativeTimeThreshold('m', 60);
  return (moment(date)).fromNow();
}

const style = {
  cursor: "move",
  // float: "left"
};
export const Profile = ({ props, name, type, isDropped, selectProfile, profile, updateSearch, state, tasks, debug, t }) => {
  const [{ opacity }, drag] = useDrag({
    type: "PROFILE",
    item: { name, type, profile },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <React.Fragment>

        <span 
            className={`position-relative`} 
            ref={drag} 
            style={{ ...style, opacity }}>
                <span 
                    data-tip={  profile.first_name + " " + profile.last_name } 
                    onClick={selectProfile.bind(this, profile)} className={"me-3 " + ((queryString.parse(props.location.search).profile === profile._id) ? 'active' : '')} key={profile._id}>
                    
                        <img width="60px" height="60px" className={`profile ${!profile.facebook_id ? 'spinssss' : ''} button-click rounded rounded-circle border border-3  ${profile.is_logged_in ? 'logged_in' : 'logged_out'} ${(state.profile_group[profile._id] && state.profile_group[profile._id][state.group]) ? '' : ' greyscale2'} ${profile?.logging_stats?.is_invalid_password ? " invalid-password" : ""}`} src={'https://images.fbzipper.com/api/users/profile/' + profile._id + "?f=" + profile.facebook_id} g="img/user1.png" alt="" />
                
              {/*  
                {!profile.locked_account && !profile.is_logged_in && <FaExclamationTriangle style={{
                            color: 'red',
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 0.6,
                            position: 'absolute',
                            top: -3,
                            left: 15,
                          }}/>}
                */}




                {profile.locked_account && <FaLock style={{
                            color: (profile.block_status && profile.block_status.banned_forever && debug) ? 'black' : 'red',
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 0.6,
                            position: 'absolute',
                            top: -3,
                            left: 15,
                          }}/>}


                {!profile.locked_account && profile.temporary_block > 0 && <FaLock style={{
                            color: 'blue',
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 1,
                            position: 'absolute',
                            top: -3,
                            left: 15,
                          }}/>}
                {!profile.locked_account && !profile.temporary_block && profile.temporary_block_link > 0 && <FaLink style={{
                            color: 'orange',
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 1,
                            position: 'absolute',
                            top: -3,
                            left: 15,
                          }}/>}
                {!profile.locked_account && !profile.temporary_block && profile.temporary_block_comment && <FaLock style={{
                            color: 'purple',
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 1,
                            position: 'absolute',
                            top: -3,
                            left: 15,
                          }}/>}

                {profile?.logging_stats?.is_invalid_password&& <FaKey style={{
                            color: 'red',
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 0.6,
                            position: 'absolute',
                            top: -3,
                            left: 15,
                          }}/>}

                {!profile.facebook_id && profile.login_try_count == 0 && <LoadingIcons.Circles stroke="#3446cd" fill="#3446cd" style={{
                            fontSize: '28px',
                            lineHeight: '41px',
                            opacity: 1,
                            position: 'absolute',
                            top: '-55px',
                            left: '13px',
                            width: '35px',

                          }}/>}



              </span>

                {(queryString.parse(props.location.search).profile === profile._id) && <span onClick={updateSearch.bind(this, {path: [':lang', 'console','profile', 'edit', profile._id]})} className="badge bg-info text-white rounded-pill button-click position-absolute top-100 start-0">
                    {t('Edit')}
                </span>}

                {!debug && <span className="badge bg-info text-white rounded-pill position-absolute bottom-10 start-0">
                    {profile.today_slot_count} / {profile.posts_per_day}
                </span>}

                {debug && tasks && tasks.count > 0 && <span className="badge bg-info text-white rounded-pill position-absolute bottom-10 start-0">
                    ? / {tasks.count}
                </span>}

              </span>
    </React.Fragment>
  );
};
