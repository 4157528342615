import React from 'react';
import { ChartDonut } from '@patternfly/react-charts';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { fasAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {Launcher} from 'react-chat-window'
import Chat from '../Chat.js'
import ManagerPicker from '../ManagerPicker.js'
import StatusPicker from '../StatusPicker.js'
import ArchiveManager from '../ArchiveManager.js'

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import Switch from "@material-ui/core/Switch";




import { FaLightbulb, FaCog, FaUser, FaExternalLinkAlt, FaCloud, FaBug, FaWhatsappSquare, FaMoneyCheckAlt } from "react-icons/fa";

import queryString from 'query-string';

function addOrRemove(array: any, value:any) {
    var index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

class Home2 extends React.Component {
    state = {
      selected_profiles: [],
      stats: {},
      show: false,
      page: 'test',
      health_check: {},
      intervalId: null,
      latest_chats: {},
      customer_service: {},
      filter: {
        paid: false,
        on: true,
        off: true,
        cloud: true,
        virtual_phone: true,
      },
    }

    update_data(props) {

    }

    refresh() {

    }

    masterLogin(user_id) {

      fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/login?user_id=${user_id}&full=true`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success(data.token);
          localStorage.setItem('user_token', data.token);
          // window.location.reload();
          window.location.href = window.location.origin;



        })
        .catch(err => {

        });




    }

    update_data(props) {
      var token = localStorage.getItem('user_token');

      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/health-check?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          
          if (data.version) {
            this.props.tutorialEvent({action: 'got_health_check'}); 
          }
          if (data.simultaneous) {
            this.props.tutorialEvent({action: 'got_browser_change'}); 
          }
          if (data.started) {
            this.props.tutorialEvent({action: 'started_application'}); 
          }

          this.setState({
            health_check: data,
          });
        })
        .catch(err => {

        });
      fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/chats/latest`, {})
        .then(response => response.json())
        .then(data => {

          this.setState({
            latest_chats: data,
          });



        })
        .catch(err => {

        });

      fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/customer-service/info`, {})
        .then(response => response.json())
        .then(data => {

          this.setState({
            customer_service: data,
          });



        })
        .catch(err => {

        });

      fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master?access_token=${token}&full=true`, {})
        .then(response => response.json())
        .then(data => {

            data.map((user) => {
              user.add_amount = 1;
            });

            if (this.state.selected_profiles.length === 0) {
              var selected_profiles = [];
              data.map((user) => {
                if (!user.exclude_from_debug &&  user.today_used_slot_count/user.daily_post_limit < 0.8 ) {
                  selected_profiles.push(user._id)
                }
              })

              this.setState({
                selected_profiles: selected_profiles,
              });
            }


          this.setState({
            master_users: data,
          });
        })
        .catch(err => {

        });
    }

    componentWillReceiveProps(props) {
      this.setState({page: this.props.location.pathname})
    }

    schedule_today_posts() {
        confirmAlert({
          title: 'Confirm to schedule posts',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);

                NotificationManager.success('success', 'removed post');

// 
                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}scheduled-tasks/schedule-today-campaign-slots?access_token=${token}&accounts=${this.state.selected_profiles.join(',')}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify({
                    //     id: id,
                    // })
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule posts');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
    }

    disableAccount(account_id) {
        confirmAlert({
          title: 'Confirm to schedule posts',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);

                NotificationManager.success('success', 'removed post');

// 
                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}admin/customer/${account_id}/disable`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify({
                    //     id: id,
                    // })
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule posts');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
    }

    addProfiles(account_id, amount) {
        confirmAlert({
          title: 'Confirm to schedule posts',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);

                NotificationManager.success('success', 'removed post');

// 
                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}api2/add-profiles/${account_id}/${amount}?access_token=${token}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify({
                    //     id: id,
                    // })
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule posts');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
    }


    
    componentWillMount(props) {
      this.setState({page: this.props.location.pathname})
        var intervalId = setInterval(() => {
        this.update_data(this.props);  
      }, 10000);
      
      this.setState({ intervalId: intervalId })

      this.update_data(this.props);  
    }

    componentWillUnmount(){
      clearInterval(this.state.intervalId)
    }

    render() {
      const { t, i18n } = this.props;
        return (
            <React.Fragment>

          <header id="master-head" className="navbar menu-absolute menu-center menu-light">
            <div className="container-fluid">
              <div id="main-logo" className="logo-container">
                <a className="logo" href="/">
                  <img src="/images/d-code-logo-dark.svg" className="logo-dark" alt="DCode" />
                  <img src="/images/d-code-logo-light.svg" className="logo-light" alt="DCode" />
                </a>
              </div>
              <div id="navigation" className="nav navbar-nav navbar-main d-none d-md-block">
                <ul id="main-menu" className="menu-primary">
                  {!this.props.user && <li className={`menu-item ${this.state.page.indexOf('/home') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'home']})}>{t('Home')}</a>
                  </li>}
                  {/*<li className={`menu-item ${this.state.page.indexOf('/faq') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'faq']})}>{t('FAQ')}</a>
                  </li>*/}
                  {this.props.user && this.props.logged_in && this.props.user.active_plan && <li className={`menu-item ${this.state.page.indexOf('/console') > -1 && this.state.page.indexOf('/console/schedule/automatic') === -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>{t('Console')}</a>
                  </li>}
                  {/*this.props.user && this.props.logged_in && this.props.user.active_plan && <li onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','schedule', 'automatic', 'day']}, 'click_automatic_schudling_nav')} className={`automatic-schudling-nav menu-item ${this.state.page.indexOf('/console/schedule/automatic') > -1 ? 'active' : 'not-active'}`}>
                    <a>{t('Automatic schudling')}</a>
                  </li>*/}
                  {/*this.props.logged_in &&*/ <li className={`menu-item ${this.state.page.indexOf('/plans') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{t('Plans')}</a>
                  </li>}
                  {/*this.props.logged_in && <li className={`menu-item ${this.state.page.indexOf('/guides') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'guides']})}>{t('Guides')}</a>
                  </li>*/}
                  {/*this.props.user && this.props.logged_in && this.props.user.active_plan && <li className={`menu-item ${this.state.page.indexOf('/console/settings') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console', 'settings']})}>{t('Settings')}</a>
                  </li>*/}
                  {!this.props.logged_in && <li className={`menu-item ${this.state.page.indexOf('/contact') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'contact']})}>{t('Contact Us')}</a>
                  </li>}
                  {/*this.props.logged_in && <li className={`menu-item ${this.state.page.indexOf('/support') > -1 ? 'active' : 'not-active'}`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'support']})}>{t('Support')}</a>
                  </li>*/}
                  {this.props.logged_in && <li className={`menu-item`}>
                    <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console'], tutorial: '1', 'current-tutoral': 'add-profile', step: 0})}>{t('Tutorial')}</a>
                  </li>}
                </ul>
              </div>
              <div className="navbar-right d-none d-md-block">
                <div className="menu-button">
                  
              
                  {this.state.master_users && this.state.master_users.length && this.props.user && this.props.user.active_plan && <a>
                    <div onClick={() => {this.setState({show_master_login: !this.state.show_master_login})}} className="btn btn-outline-primary btn-light" style={{fontSize: '30px;'}}>
                      {<FaUser style={{
                        color: '#fff',
                        fontSize: '24px',
                        lineHeight: '41px',
                      }}/>}
                    </div>


            {this.state.show_master_login && <div style={{
              position: 'absolute',
              width: '1937px',
              left: '57px',
            }}>

      <div className="dropdown" style={{zIndex: 1}}>
        


        <ul className="" style={{
          background: '#b9b6b6',
          textAlign: 'left',
          
        }}>

          <span>

            <Switch
              classes={this.props.classes}
              checked={this.state.filter.virtual_phone}
              onClick={(e) => { 
                this.state.filter.virtual_phone = !this.state.filter.virtual_phone;

                this.setState({filter: this.state.filter})
              }}
            />
            {<FaWhatsappSquare style={{
              color: '#25D366',
              fontSize: '24px',
              lineHeight: '41px',
            }}/>}


          </span>
          <span>

            <Switch
              classes={this.props.classes}
              checked={this.state.filter.on}
              onClick={(e) => { 
                this.state.filter.on = !this.state.filter.on;

                this.setState({filter: this.state.filter})
              }}
            />
            {<FaLightbulb style={{
              color: '#3ee724',
              fontSize: '24px',
              lineHeight: '41px',
            }}/>}


          </span>
          <span>

            <Switch
              classes={this.props.classes}
              checked={this.state.filter.off}
              onClick={(e) => { 
                this.state.filter.off = !this.state.filter.off;

                this.setState({filter: this.state.filter})
              }}
            />
            {<FaLightbulb style={{
              color: 'red',
              fontSize: '24px',
              lineHeight: '41px',
            }}/>}
          </span>
          <span>
            <Switch
              classes={this.props.classes}
              checked={this.state.filter.paid}
              onClick={(e) => { 
                this.state.filter.paid = !this.state.filter.paid;

                this.setState({filter: this.state.filter})
              }}
            />
            {<FaLightbulb style={{
              color: 'black',
              fontSize: '24px',
              lineHeight: '41px',
            }}/>}
          </span>
          <span>
            <Switch
              classes={this.props.classes}
              checked={this.state.filter.cloud}
              onClick={(e) => { 
                this.state.filter.cloud = !this.state.filter.cloud;

                this.setState({filter: this.state.filter})
              }}
            />
            {<FaCloud style={{
              color: 'grey',
              fontSize: '24px',
              lineHeight: '41px',
            }}/>}

            {<div>
                <button onClick={this.schedule_today_posts.bind(this)}>Schedule Today Posts</button>
            </div>}


          </span>



          {this.state.master_users && this.state.master_users.filter((user) => {
            if (!this.state.filter.paid && user.plan_ended) {
              return false;
            }

            if (this.state.filter.paid && user.plan_ended) {
              return true;
            }

            if (!this.state.filter.on && (user.health_check && user.health_check.started)) {
              return false;
            }

            if (!this.state.filter.off && (!user.health_check || (user.health_check && !user.health_check.started))) {
              return false;
            }
            if (!this.state.filter.cloud && (user.health_check && !user.health_check.version)) {
              return false;
            }


            return true;
          }).map((user) => <>

            <li>
            <a className="user-block" >
              {!user.exclude_from_debug && <span><input
                value={user._id}
              checked={this.state.selected_profiles.indexOf(user._id) > -1}
              type="checkbox"
              onChange={(e) => {
                addOrRemove(this.state.selected_profiles, e.target.value)
                this.setState({selected_profiles: this.state.selected_profiles})
              }}

              className=""
              /></span>} 

              {(!user.health_check || !user.health_check.started) && <span>&nbsp; {user.status && user.status.last_response}</span>} 

              <div className="inline-list-el">
                {<FaLightbulb style={{
                  color: user.health_check && user.health_check.started ? '#3ee724' : 'red',
                  fontSize: '24px',
                  lineHeight: '41px',
                }}/>}

                {user.plan_ended && <span>
                  <FaLightbulb style={{
                    color: 'black',
                    fontSize: '24px',
                    lineHeight: '41px',
                  }}/>
              </span>}
                {user.health_check && !user.health_check.version && <span>
                  <FaCloud style={{
                    color: 'grey',
                    fontSize: '24px',
                    lineHeight: '41px',
                  }}/>
              </span>}
              </div>

              <span>
                  <div className="inline-list-el">
                    {user.username}
                  </div>
                  <div className="inline-list-el">({user.first_name} {user.last_name})</div>
                  <div className={`inline-list-el ${user.today_used_slot_count < 10 ? 'post-warning' : 'post-success'}`}>{user.today_used_slot_count}/{user.daily_post_limit}</div>
                  <div className={`inline-list-el ${user.today_published === 0 ? 'post-warning' : 'post-success'}`}>{user.today_published === 0 ? '!!!!!!!!!' : ''}</div>
                  <div className={`inline-list-el ${user.today_groups === 0 ? 'post-warning' : 'post-success'}`}>{user.today_groups === 0 ? '@@@@@' : `@@-${user.today_groups}`}</div>
                  <div className="inline-list-el">{user.customer_info && user.customer_info.occupation}</div>
                  
                  {user._campiagns && <div className="inline-list-el">
                    {user._campiagns.filter((a) => a.request_for_approval).length && <span class="badge badge-danger badge-danger-waiting">{user._campiagns.filter((a) => a.request_for_approval).length || 0}</span>}
                    {user._campiagns.filter((a) => !a.request_for_approval).length && <span class="badge badge-warning">{user._campiagns.filter((a) => !a.request_for_approval).length || 0}</span>}
                  </div>}
                  

                  <div className={`badge badge-pill inline-list-el ${user.daily_post_limit/user.availalbe_profile_count > 14 ? 'badge-danger' : 'badge-blue'}`}>{user.availalbe_profile_count}/{user.profile_count}<FaUser/></div>
                  


              </span> 
              <button onClick={this.masterLogin.bind(this, user._id)}>Visit Console</button>
              <a target={'_blank'} href={'/admin/customer/' + user._id}><button>Visit CRM <FaExternalLinkAlt style={{
                        color: 'rgb(209 108 108)',
                        fontSize: '24px',
                        lineHeight: '41px',
                      }}/></button></a>
              {user.plan_ended && <button className="btn-warning" onClick={this.disableAccount.bind(this, user._id)}>Disable</button>}
              {/*parseInt((user.daily_post_limit/14) - user.availalbe_profile_count) > 0 &&*/ <span>
                <button onClick={this.addProfiles.bind(this, user._id, user.add_amount)}>Add {user.add_amount} profiles --- missing {parseInt((user.daily_post_limit/14) - user.availalbe_profile_count)}</button>
                <input
                  type="number" 
                  style={{width: '50px'}} 
                  value={user.add_amount}
                  onChange={(e) => {
                    user.add_amount = e.target.value;
                    this.setState({master_users: this.state.master_users})
                  }}
                />
              </span>}
              {user.virtual_numbers.length > 0 && <span>
                {<FaWhatsappSquare style={{
                  color: '#25D366',
                  fontSize: '24px',
                  lineHeight: '41px',
                }}/>} {user.cdr_day || 0} / {user.cdr_month || 0}
              </span>}
              {user.payment_tokens && <span><FaMoneyCheckAlt style={{
                  color: 'green',
                  fontSize: '24px',
                  lineHeight: '41px',
                }}/></span>}
            </a>
          </li>
          <li>
<div className="stepper">
  <div className="step">
    <div>{user.has_profiles ? <span>✅</span> : <span>❌{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'הוסף פרופיל'}/>}</span>} הוסף פרופיל}</div>
  </div>
  <div className="step">{user.has_profiles_2_factor ? <span>✅</span> : <span>❌{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'אימות דו שלבי'}/>}</span>} אימות דו שלבי}</div>
  <div className="step">{user.add_campaigns ? <span>✅</span> : <span>❌{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'הוסף קמפיינים'}/>}</span>} הוסף קמפיינים}</div>
  <div className="step">{user.add_posts ? <span>✅</span> : <span>❌{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'הוספת פוסטים'}/>}</span>} הוספת פוסטים}</div>
  <div className="step">{user.selected_groups ? <span>✅</span> : <span>❌{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'קבוצות נבחרות'}/>}</span>} קבוצות נבחרות}</div>
  <div className="step">{user.start_publishing ? <span>✅</span> : <span>❌{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'התחלת פרסום'}/>}</span>} התחלת פרסום}</div>

  <div className="step">📞 {user.phone}</div>
  <div className="step">{<Chat latest_chats={this.state.latest_chats} user={user._id} type={'normal'}/>}</div>
  
  {/*<div className="step">SU {<Chat latest_chats={this.state.latest_chats} user={user._id} type={'support'}/>}</div>

  <div className="step">WA {<Chat latest_chats={this.state.latest_chats} user={user._id} type={'whatsapp'}/>}</div>*/}
  <div className="step"><ManagerPicker customer_service={this.state.customer_service} user={user._id} type={'whatsapp'}/></div>
  <div className="step">{(queryString.parse(this.props.location.search).secret || true) ? <>
    <ArchiveManager customer_service={this.state.customer_service} user={user._id} type={'whatsapp'}/>
  </> : <></>}</div>
</div>
<div className="stepper">
  <div className="step"><StatusPicker customer_service={this.state.customer_service} user={user._id} type={'whatsapp'}/></div>
</div>


          </li>
          </>)}
        </ul>
      </div>


              </div>}





                  </a>}

                  {this.props.user && !this.props.user.personal_plan && this.props.user.active_plan && <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','settings']})}>
                    <div onClick={this.props.tutorialEvent.bind(this, {action: 'open-settings'})} className="settings-popup-trigger btn btn-outline-primary btn-light" style={{fontSize: '30px'}}>
                      {<FaCog style={{
                        color: '#fff',
                        fontSize: '24px',
                        lineHeight: '41px',
                      }}/>}
                    </div>
                  </a>}
                  {this.state.master_users && this.state.master_users.length && this.props.user && this.props.user && <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','settings']})}>
                    <a href={`/admin/customer/${this.props.user._id}`} target="_blank" className="settings-popup-trigger btn btn-outline-primary btn-light" style={{fontSize: '30px'}}>
                      {<FaExternalLinkAlt style={{
                        color: '#fff',
                        fontSize: '24px',
                        lineHeight: '41px',
                      }}/>}
                    </a>
                  </a>}
                  {this.state.master_users && this.state.master_users.length && this.props.user && this.props.user && <a>
                    <a onClick={this.props.updateSearch.bind(this, {admin: (!queryString.parse(this.props.location.search).admin ? 'true' : undefined) })} style={{fontSize: '30px'}}>
                      {<FaBug style={{
                        color: queryString.parse(this.props.location.search).admin ? 'red' : '#fff',
                        fontSize: '24px',
                        lineHeight: '41px',
                      }}/>}
                    </a>
                  </a>}

                  {this.props.user && !this.props.user.personal_plan && this.props.user.active_plan && this.props.logged_in && <a data-tip={this.state.health_check.version ? `${this.state.health_check.version} - ${this.state.health_check.simultaneous} browsers - ${this.state.health_check.restart_count}` : `OFF` } onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','program-settings']})}>
                    <div className="btn application-status btn-outline-primary btn-light">
                      {<FaLightbulb style={{
                        color: this.state.health_check.started ? '#3ee724' : 'red',
                        fontSize: '24px',
                        lineHeight: '41px',
                      }}/>}
                    </div>
                  </a>}

                  {this.props.user && !this.props.user.active_plan && <a data-tip={this.state.health_check.version ? `${this.state.health_check.version} - ${this.state.health_check.simultaneous} browsers - ${this.state.health_check.restart_count}` : `OFF` } onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>
                    <div className="btn btn-outline-primary btn-light">
                      {<React.Fragment>
                        <span style={{color: 'red'}}>{t('No Package')}</span>
                      </React.Fragment>}
                    </div>
                  </a>}

                  {!this.props.logged_in && <Link to={`/${this.props.match.params.lang}/login`}>
                    <div className=" btn btn-outline-primary btn-light">{t('Sign in')} / {t('Register Action')}</div>
                  </Link>}
                  {this.props.logged_in && <a onClick={this.props.logout}>
                    <div className="btn btn-outline-primary btn-light">{t('Hello')} {this.props.user && this.props.user.username} {t('logout')}</div>
                  </a>}




                </div>
              </div>
            </div>
          </header>


            </React.Fragment>
        );
    }
}

Home2.propTypes = {};

Home2.defaultProps = {};

export default Home2;

